// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../../styleguide/icons/IconSelect.res.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_City from "../../../../routes/common/Routes_City.res.js";
import * as Api_Location from "../../../../api/locations/Api_Location.res.js";
import * as LocationTile from "../location-tile/LocationTile.res.js";
import * as Routes_State from "../../../../routes/common/Routes_State.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocalityShowCities from "../locality-show-cities/LocalityShowCities.res.js";
import * as LocalityShowStates from "../locality-show-states/LocalityShowStates.res.js";
import * as LocalityShowScss from "./LocalityShow.scss";
import * as LocationsIndexHeader from "../locations-index-header/LocationsIndexHeader.res.js";
import * as LocalityShowProviders from "../locality-show-providers/LocalityShowProviders.res.js";
import * as LocationsIndexContent from "../../index/components/LocationsIndexContent.res.js";
import * as LocationsIndexViewButtons from "../locations-index-view-buttons/LocationsIndexViewButtons.res.js";
import * as LocationsIndexBookACallSection from "../../index/components/common/LocationsIndexBookACallSection.res.js";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";

var css = LocalityShowScss;

function LocalityShow(props) {
  var userRole = props.userRole;
  var totalLocations = props.totalLocations;
  var providersIndex = props.providersIndex;
  var totalProviders = props.totalProviders;
  var totalCities = props.totalCities;
  var totalStates = props.totalStates;
  var index = props.index;
  var city = props.city;
  var countryState = props.countryState;
  var country = props.country;
  var initialState = React.useMemo((function () {
          var tmp;
          tmp = countryState !== undefined ? (
              city !== undefined ? (
                  userRole === "Admin" ? [
                      {
                        linkName: "Home",
                        linkPath: "/"
                      },
                      {
                        linkName: "All Data Centers",
                        linkPath: Routes_Location.index
                      },
                      {
                        linkName: "Countries",
                        linkPath: Routes_Country.index
                      },
                      {
                        linkName: country.name,
                        linkPath: Routes_Country.show(country.slug)
                      },
                      {
                        linkName: countryState.name,
                        linkPath: Routes_State.show(countryState.slug, country.slug)
                      },
                      {
                        linkName: city.name,
                        linkPath: Routes_City.show(city.slug, countryState.slug, country.slug)
                      },
                      {
                        linkName: "Edit",
                        linkPath: Routes_City.Dashboard.edit(city.id)
                      }
                    ] : [
                      {
                        linkName: "Home",
                        linkPath: "/"
                      },
                      {
                        linkName: "All Data Centers",
                        linkPath: Routes_Location.index
                      },
                      {
                        linkName: "Countries",
                        linkPath: Routes_Country.index
                      },
                      {
                        linkName: country.name,
                        linkPath: Routes_Country.show(country.slug)
                      },
                      {
                        linkName: countryState.name,
                        linkPath: Routes_State.show(countryState.slug, country.slug)
                      },
                      {
                        linkName: city.name,
                        linkPath: Routes_City.show(city.slug, countryState.slug, country.slug)
                      }
                    ]
                ) : (
                  userRole === "Admin" ? [
                      {
                        linkName: "Home",
                        linkPath: "/"
                      },
                      {
                        linkName: "All Data Centers",
                        linkPath: Routes_Location.index
                      },
                      {
                        linkName: "Countries",
                        linkPath: Routes_Country.index
                      },
                      {
                        linkName: country.name,
                        linkPath: Routes_Country.show(country.slug)
                      },
                      {
                        linkName: countryState.name,
                        linkPath: Routes_State.show(countryState.slug, country.slug)
                      },
                      {
                        linkName: "Edit",
                        linkPath: Routes_State.Dashboard.edit(countryState.id)
                      }
                    ] : [
                      {
                        linkName: "Home",
                        linkPath: "/"
                      },
                      {
                        linkName: "All Data Centers",
                        linkPath: Routes_Location.index
                      },
                      {
                        linkName: "Countries",
                        linkPath: Routes_Country.index
                      },
                      {
                        linkName: country.name,
                        linkPath: Routes_Country.show(country.slug)
                      },
                      {
                        linkName: countryState.name,
                        linkPath: Routes_State.show(countryState.slug, country.slug)
                      }
                    ]
                )
            ) : (
              userRole === "Admin" ? [
                  {
                    linkName: "Home",
                    linkPath: "/"
                  },
                  {
                    linkName: "All Data Centers",
                    linkPath: Routes_Location.index
                  },
                  {
                    linkName: "Countries",
                    linkPath: Routes_Country.index
                  },
                  {
                    linkName: country.name,
                    linkPath: Routes_Country.show(country.slug)
                  },
                  {
                    linkName: "Edit",
                    linkPath: Routes_Country.Dashboard.edit(country.id)
                  }
                ] : [
                  {
                    linkName: "Home",
                    linkPath: "/"
                  },
                  {
                    linkName: "All Data Centers",
                    linkPath: Routes_Location.index
                  },
                  {
                    linkName: "Countries",
                    linkPath: Routes_Country.index
                  },
                  {
                    linkName: country.name,
                    linkPath: Routes_Country.show(country.slug)
                  }
                ]
            );
          return {
                  status: {
                    TAG: "Ready",
                    _0: index
                  },
                  currentPage: 1,
                  totalPages: 1,
                  sortBy: undefined,
                  search: "",
                  breadcrumbs: tmp,
                  localityName: countryState !== undefined ? (
                      city !== undefined ? city.name : countryState.name
                    ) : country.name,
                  localitySubName: countryState !== undefined ? (
                      city !== undefined ? city.name + ", " + countryState.name + ", " + country.name : countryState.name + ", " + country.name
                    ) : country.name,
                  mapEnabled: false,
                  view: "Grid",
                  totalLocalities: 0,
                  totalLocations: totalLocations,
                  totalProviders: totalProviders,
                  currentTotalLocations: totalLocations,
                  providerIds: [],
                  userRole: userRole
                };
        }), []);
  var container = React.useRef(null);
  var searchLocations = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchLocations" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              if (city !== undefined) {
                                return $$Promise.wait(Api_Location.City.show(state.currentPage, city.id, state.sortBy, state.search, state.providerIds), (function (x) {
                                              if (x.TAG === "Ok") {
                                                return dispatch({
                                                            TAG: "SucceedLocationsFetch",
                                                            _0: x._0
                                                          });
                                              }
                                              SentryLogger.error1({
                                                    rootModule: "LocalityShow",
                                                    subModulePath: /* [] */0,
                                                    value: "make",
                                                    fullPath: "LocalityShow.make"
                                                  }, "LocationsIndex::FetchLocations::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                              dispatch("FailLocationsFetch");
                                            }));
                              } else {
                                return $$Promise.wait(Api_Location.fetchLocationsIndex(state.currentPage, Caml_option.some(country.id), countryState !== undefined ? Caml_option.some(countryState.id) : undefined, city !== undefined ? Caml_option.some(city.id) : undefined, state.sortBy, state.search, state.providerIds), (function (x) {
                                              if (x.TAG === "Ok") {
                                                return dispatch({
                                                            TAG: "SucceedLocationsFetch",
                                                            _0: x._0
                                                          });
                                              }
                                              SentryLogger.error1({
                                                    rootModule: "LocalityShow",
                                                    subModulePath: /* [] */0,
                                                    value: "make",
                                                    fullPath: "LocalityShow.make"
                                                  }, "LocationsIndex::FetchLocations::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                              dispatch("FailLocationsFetch");
                                            }));
                              }
                            })
                        };
              case "FailLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLocationsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: "",
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: [],
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedLocationsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: res.totalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              breadcrumbs: state.breadcrumbs,
                              localityName: state.localityName,
                              localitySubName: state.localitySubName,
                              mapEnabled: state.mapEnabled,
                              view: state.view,
                              totalLocalities: state.totalLocalities,
                              totalLocations: state.totalLocations,
                              totalProviders: state.totalProviders,
                              currentTotalLocations: state.currentTotalLocations,
                              providerIds: state.providerIds,
                              userRole: state.userRole
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: action._0,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          },
                          _1: searchLocations
                        };
              case "PerformSearch" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              breadcrumbs: state.breadcrumbs,
                              localityName: state.localityName,
                              localitySubName: state.localitySubName,
                              mapEnabled: state.mapEnabled,
                              view: state.view,
                              totalLocalities: state.totalLocalities,
                              totalLocations: state.totalLocations,
                              totalProviders: state.totalProviders,
                              currentTotalLocations: state.currentTotalLocations,
                              providerIds: state.providerIds,
                              userRole: state.userRole
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  }
              case "ToggleView" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: action._0,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdateTotalProviders" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: action._0,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdateTotalLocalities" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: action._0,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            breadcrumbs: state.breadcrumbs,
                            localityName: state.localityName,
                            localitySubName: state.localitySubName,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: action._0,
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.view;
  var tmp;
  var exit = 0;
  switch (match$1) {
    case "Grid" :
    case "List" :
        exit = 1;
        break;
    case "Map" :
        var res = state.status;
        var tmp$1;
        tmp$1 = typeof res !== "object" ? (
            res === "FetchingLocations" ? null : "Something went wrong."
          ) : JsxRuntime.jsx(ComponentsImportsLoadableJsx.LocationsIndexMap, {
                className: css.map,
                locations: res._0.mapLocations
              });
        tmp = JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "Views:",
                                    className: css.viewsText
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(LocationsIndexViewButtons.make, {
                                          view: state.view,
                                          updateView: (function (view) {
                                              dispatch({
                                                    TAG: "ToggleView",
                                                    _0: view
                                                  });
                                            })
                                        }),
                                    className: css.views
                                  })
                            ],
                            className: css.locationsViews
                          }),
                      className: css.mapLocationsHeader
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp$1,
                      className: css.mapLocations
                    })
              ],
              className: css.mapContainer
            });
        break;
    
  }
  if (exit === 1) {
    var x = state.sortBy;
    var tmp$2;
    if (x !== undefined) {
      var variant = x.NAME;
      tmp$2 = variant === "Provider" ? (
          x.VAL === "Desc" ? "Sort by Provider, Z to A" : "Sort by Provider, A to Z"
        ) : (
          variant === "State" ? (
              x.VAL === "Desc" ? "Sort by State, Z to A" : "Sort by State, A to Z"
            ) : (
              variant === "Popular" ? (
                  x.VAL === "Desc" ? "Sort by Most Popular" : null
                ) : (
                  variant === "City" ? (
                      x.VAL === "Desc" ? "Sort by City, Z to A" : "Sort by City, A to Z"
                    ) : (
                      x.VAL === "Desc" ? "Sort by Country, Z to A" : "Sort by Country, A to Z"
                    )
                )
            )
        );
    } else {
      tmp$2 = "Choose Sort Criteria";
    }
    var res$1 = state.status;
    var tmp$3;
    if (typeof res$1 !== "object") {
      if (res$1 === "FetchingLocations") {
        var match$2 = state.view;
        var tmp$4;
        switch (match$2) {
          case "List" :
              tmp$4 = css.tilesList;
              break;
          case "Grid" :
          case "Map" :
              tmp$4 = css.tiles;
              break;
          
        }
        tmp$3 = JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Spinner.make, {
                    size: "XL",
                    color: "Teal"
                  }),
              className: tmp$4
            });
      } else {
        tmp$3 = JsxRuntime.jsx("div", {
              children: "Something went wrong. Please try again later."
            });
      }
    } else {
      var res$2 = res$1._0;
      var match$3 = state.view;
      var tmp$5;
      switch (match$3) {
        case "List" :
            tmp$5 = css.tilesList;
            break;
        case "Grid" :
        case "Map" :
            tmp$5 = css.tiles;
            break;
        
      }
      var x$1 = res$2.locations.length;
      var match$4 = state.status;
      var tmp$6;
      if (typeof match$4 !== "object") {
        tmp$6 = null;
      } else if (res$2.totalPages < 2) {
        var match$5 = res$2.totalLocations;
        tmp$6 = match$5 !== 0 ? (
            match$5 !== 1 ? "Showing all" : "Showing 1"
          ) : (
            state.search === "" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: "No locations were found in " + state.localityName + ". Visit one of the following pages to find\n                                        a location with active data centers:"
                          }),
                      JsxRuntime.jsxs("ul", {
                            children: [
                              JsxRuntime.jsx("li", {
                                    children: JsxRuntime.jsx("a", {
                                          children: "Countries with Data Centers",
                                          href: Routes_Country.index
                                        })
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: JsxRuntime.jsx("a", {
                                          children: "States with Data Centers",
                                          href: Routes_State.index
                                        })
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: JsxRuntime.jsx("a", {
                                          children: "Cities with Data Centers",
                                          href: Routes_City.index
                                        })
                                  })
                            ]
                          })
                    ]
                  }) : JsxRuntime.jsx("p", {
                    children: "No locations were found with that search query. Please try a different search."
                  })
          );
      } else {
        tmp$6 = "Showing " + (String(res$2.offset + 1 | 0) + (" to " + (String(res$2.offset + res$2.locations.length | 0) + (" of " + String(res$2.totalLocations)))));
      }
      tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx("div", {
                    children: Belt_Array.map(res$2.locations, (function ($$location) {
                            var match = state.userRole;
                            var tmp;
                            switch (match) {
                              case "Provider" :
                              case "User" :
                              case "Visitor" :
                                  tmp = false;
                                  break;
                              default:
                                tmp = $$location.providerAgreement;
                            }
                            var match$1 = state.view;
                            var tmp$1;
                            switch (match$1) {
                              case "List" :
                                  tmp$1 = "List";
                                  break;
                              case "Grid" :
                              case "Map" :
                                  tmp$1 = "Tile";
                                  break;
                              
                            }
                            return JsxRuntime.jsx(LocationTile.make, {
                                        location: $$location,
                                        markSponsored: countryState !== undefined ? (
                                            city !== undefined ? $$location.sponsoredCity : $$location.sponsoredState
                                          ) : $$location.sponsoredCountry,
                                        deferImage: true,
                                        userLoginStatus: "LoggedOut",
                                        showAgreementCheck: tmp,
                                        style: tmp$1
                                      }, ID.toString($$location.id));
                          })),
                    className: Cx.cx([
                          tmp$5,
                          x$1 <= 3 ? "" : css.tilesSpaceBetween
                        ])
                  }),
              JsxRuntime.jsxs("div", {
                    children: [
                      res$2.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                              currentPage: res$2.currentPage,
                              totalPages: res$2.totalPages,
                              onPageClick: (function (pageNum) {
                                  dispatch({
                                        TAG: "UpdatePage",
                                        _0: pageNum
                                      });
                                })
                            }) : null,
                      JsxRuntime.jsx("div", {
                            children: tmp$6,
                            className: css.paginationInfo
                          })
                    ],
                    className: css.paginationBar
                  })
            ]
          });
    }
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs(Popover.make, {
                                children: [
                                  JsxRuntime.jsxs(Popover.Trigger.make, {
                                        className: css.popoverTrigger,
                                        children: [
                                          JsxRuntime.jsx(Control.AsLink.make, {
                                                color: "Gray",
                                                className: css.popoverLabel,
                                                onClick: (function (param) {
                                                    
                                                  }),
                                                children: tmp$2
                                              }),
                                          JsxRuntime.jsx(IconSelect.make, {
                                                size: "XS",
                                                color: "GrayText",
                                                className: css.iconSelect
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Popover.Body.make, {
                                        position: {
                                          TAG: "Below",
                                          _0: "LeftEdge"
                                        },
                                        className: css.popoverBody,
                                        children: [
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Most Popular",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "Popular",
                                                                  VAL: "Desc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Provider, A-Z",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "Provider",
                                                                  VAL: "Asc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Provider, Z-A",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "Provider",
                                                                  VAL: "Desc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "City, A-Z",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "City",
                                                                  VAL: "Asc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "City, Z-A",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "City",
                                                                  VAL: "Desc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "State, A-Z",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "State",
                                                                  VAL: "Asc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "State, Z-A",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "State",
                                                                  VAL: "Desc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Country, A-Z",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "Country",
                                                                  VAL: "Asc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Country, Z-A",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "SortBy",
                                                                _0: {
                                                                  NAME: "Country",
                                                                  VAL: "Desc"
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              })
                                        ]
                                      })
                                ]
                              }),
                          className: css.locationsSort
                        }),
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("span", {
                                  children: "Views:",
                                  className: css.viewsText
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(LocationsIndexViewButtons.make, {
                                        view: state.view,
                                        updateView: (function (view) {
                                            dispatch({
                                                  TAG: "ToggleView",
                                                  _0: view
                                                });
                                          })
                                      }),
                                  className: css.views
                                })
                          ],
                          className: css.locationsViews
                        })
                  ],
                  className: css.locationsHeader
                }),
            tmp$3
          ],
          className: css.locationTilesContainer
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsIndexHeader.make, {
                      title: state.localityName + " Data Centers Locations",
                      locationsCount: state.currentTotalLocations,
                      updateSearch: (function (search) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateSearchInput",
                                  _0: search
                                });
                          };
                        }),
                      updateProviderIds: (function (ids) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateProviderIds",
                                  _0: ids
                                });
                          };
                        }),
                      userRole: state.userRole,
                      breadcrumbs: state.breadcrumbs,
                      countryId: Caml_option.some(country.id),
                      stateId: countryState !== undefined ? Caml_option.some(countryState.id) : undefined,
                      cityId: city !== undefined ? Caml_option.some(city.id) : undefined,
                      reset: (function () {
                          dispatch("Reset");
                        })
                    }),
                tmp,
                JsxRuntime.jsx(LocationsIndexContent.make, {
                      totalLocations: totalLocations,
                      totalProviders: totalProviders,
                      totalCountries: 1,
                      totalStates: totalStates !== undefined ? totalStates : 0,
                      totalCities: totalCities !== undefined ? totalCities : 0,
                      topProviders: (function (__x) {
                            return Js_array.slice(0, 10, __x);
                          })(providersIndex.providers),
                      localityName: state.localitySubName,
                      content: props.content,
                      localityType: countryState !== undefined ? (
                          city !== undefined ? "City" : "State"
                        ) : (
                          city !== undefined ? "Global" : "Country"
                        ),
                      administrativeDivision: props.administrativeDivision,
                      administrativeDivisionPlural: props.administrativeDivisionPlural
                    }),
                countryState !== undefined ? (
                    city !== undefined ? (
                        totalProviders <= 10 ? null : JsxRuntime.jsx(LocalityShowProviders.make, {
                                localityName: state.localitySubName,
                                providers: providersIndex
                              })
                      ) : JsxRuntime.jsx(LocalityShowCities.make, {
                            localityName: state.localitySubName,
                            country: country,
                            countryState: Belt_Option.getExn(countryState),
                            cities: Belt_Option.getExn(props.citiesIndex),
                            providers: providersIndex
                          })
                  ) : (
                    city !== undefined ? null : JsxRuntime.jsx(LocalityShowStates.make, {
                            country: country,
                            states: Belt_Option.getExn(props.statesIndex),
                            providers: providersIndex
                          })
                  ),
                JsxRuntime.jsx(LocationsIndexBookACallSection.make, {})
              ],
              className: css.pageContainer
            });
}

var make = LocalityShow;

export {
  css ,
  make ,
}
/* css Not a pure module */
